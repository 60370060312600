import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import NormalBanner from "./NormalBanner/NormalBanner";
import CallAndMessageButtons from '../CallAndMessageButtons';
import NamePage from './NamePage';
import background from '../../img/Background.jpg';
const NormalLayout = ({ children }) => {
    const containerStyle = {
        background: `url(${background}) center center/cover no-repeat fixed`,
        minHeight: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <div style={containerStyle}>
            <Header />
            <NormalBanner />
            <NamePage />
            {children}
            <CallAndMessageButtons />
            <Footer />
        </div>
    );
}

export default NormalLayout