import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../img/logo-Saxnart.png';

const HeaderPC = () => {
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState('');

    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="bg-black/[.6] fixed w-full h-24 flex justify-center flex-row z-50">
            <div className="navbar flex justify-around text-white h-24 w-[80%] items-center relative z-50">
                <Link
                    to="/"
                    className={`w-[15%] h-[100%] flex items-center justify-center hover:text-orange-600 hover:font-bold ${
                        location.pathname === '/' ? 'text-orange-600 font-bold' : ''
                    }`}
                >
                    {t('Trang Chủ')}
                </Link>
                <Link
                    to="/event"
                    className={`w-[15%] h-[100%] flex items-center justify-center hover:text-orange-600 hover:font-bold ${
                        location.pathname === '/event' ? 'text-orange-600 font-bold' : ''
                    }`}
                >
                    {t('Sự Kiện')}
                </Link>
                <div
                    className={`w-[15%] h-[100%] flex items-center justify-center relative group hover:text-orange-600 hover:font-bold ${
                        selectedItem === 'saxnart' ? 'text-orange-600 font-bold' : ''
                    }`}
                    onClick={() => handleItemClick('saxnart')}
                >
                    {t("Chuyện Của Sax N'Art")}
                    <ul className="absolute text-white text-center bg-[#262626] top-[5rem] p-10 w-[16vw] invisible group-hover:visible font-sans max-h-[20rem] overflow-y-auto">
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/sax-n'art"> {t("Chuyện Sax N'Art")} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/artist"> {t('Chuyện Nghệ Sĩ')} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/blog"> {t('PodCard & Blog')} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/gallery"> {t("Sax N'Art Gallery")} </Link>
                        </li>
                    </ul>
                </div>
                <Link to="/" className="w-[15%] h-[100%] flex items-center justify-center">
                    <img src={logo} alt="Saxnart Logo" className="h-12" />
                </Link>
                <div
                    className={`w-[15%] h-[100%] flex items-center justify-center relative group hover:text-orange-600 hover:font-bold ${
                        selectedItem === 'membership' ? 'text-orange-600 font-bold' : ''
                    }`}
                    onClick={() => handleItemClick('membership')}
                >
                    Membership
                    <ul className="absolute text-white text-center bg-[#262626] top-[5rem] p-10 w-[16vw] invisible group-hover:visible font-sans max-h-[20rem] overflow-y-auto">
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/guide"> {t('Hướng dẫn')} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/category"> {t('Phân loại & quyền lợi')} </Link>
                        </li>
                    </ul>
                </div>
                <div
                    className={`w-[15%] h-[100%] flex items-center justify-center relative group hover:text-orange-600 hover:font-bold ${
                        selectedItem === 'lienhe' ? 'text-orange-600 font-bold' : ''
                    }`}
                    onClick={() => handleItemClick('lienhe')}
                >
                    {t('Liên Hệ')}
                    <ul className="absolute text-white text-center bg-[#262626] top-[5rem] p-10 w-[16vw] invisible group-hover:visible font-sans max-h-[20rem] overflow-y-auto">
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/contact"> {t('Liên hệ')} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/bookingevent"> {t('Đặt Sự Kiện')} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/feedback"> {t('Góp Ý')} </Link>
                        </li>
                        <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                            <Link to="/hotline"> {t('Hotline đặt chỗ')} </Link>
                        </li>
                    </ul>
                </div>
                <Link
                    to="/booking"
                    className="w-1/2 md:w-1/4 lg:w-1/6 h-1/2 flex items-center justify-center border border-white rounded-3xl hover:text-black hover:bg-white"
                >
                    {t('RESERVE NOW')}
                </Link>
                <div className="flex space-x-2 ml-10">
                    <button onClick={() => changeLanguage('vi')} className="hover:text-orange-600">
                        VI
                    </button>
                    <button onClick={() => changeLanguage('en')} className="hover:text-orange-600">
                        EN
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HeaderPC;
