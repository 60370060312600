import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationVI from '../locales/vi/translation.json';

const resources = {
    en: {
        translation: translationEN,
    },
    vi: {
        translation: translationVI,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'vi', // Ngôn ngữ mặc định là tiếng Việt
    fallbackLng: 'vi', // Nếu ngôn ngữ không tìm thấy, sử dụng tiếng Việt
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
