import React, { Fragment, createContext, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from './components/ScrollToTop';
import NormalLayout from './components/layouts/NormalLayout';
import { publicRoutes } from './routes/Routes';
import Loading from './pages/Loading';

export const NamePageContext = createContext();
export const BannerPageContext = createContext();

function App() {
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const decodedToken = decodeToken(token);
      if (decodedToken && decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        const expiresIn = decodedToken.exp - currentTime;
        const threshold = 2 * 60;

        if (expiresIn < threshold) {
          setTokenExpired(true);
        }

        const timeoutId = setTimeout(() => {
          handleLogout();
        }, expiresIn * 1000);

        // Lưu timeoutId vào state để có thể xóa nó khi component bị hủy
        return () => clearTimeout(timeoutId);
      }
    }
  }, []);

  const decodeToken = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      return null;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setTokenExpired(true);
  };

  return (
    <Router>
      <ToastContainer />
      <ScrollToTop />
      <div className='App'>
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            let Layout = NormalLayout;

            if (route.layout) {
              Layout = route.layout;
            } else if (route.layout === null) {
              Layout = Fragment;
            }

            if (route.Authen === "private") {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Suspense fallback={<Loading/>}>
                      {localStorage.getItem('accessToken') && !tokenExpired ? (
                        <NamePageContext.Provider value={route.name}>
                          <BannerPageContext.Provider value={route.bannerImage}>
                            <Layout>
                              <Page />
                            </Layout>
                          </BannerPageContext.Provider>
                        </NamePageContext.Provider>
                      ) : (
                        <Navigate to="/login" />
                      )}
                    </Suspense>
                  }
                />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Suspense fallback={<Loading/>}>
                      <NamePageContext.Provider value={route.name}>
                        <BannerPageContext.Provider value={route.bannerImage}>
                          <Layout>
                            <Page />
                          </Layout>
                        </BannerPageContext.Provider>
                      </NamePageContext.Provider>
                    </Suspense>
                  }
                />
              );
            }
          })}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
