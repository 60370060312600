// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for mobile header */
/* .navbar { */
/* background-color: #333; */
/* } */

.menu-item {
    padding: 1rem;
    border-bottom: 1px solid #555;
}

.menu-reserve {
    background-color: #f57c00;
    color: #ffffff;
}

/* Styles for submenu */
.submenu {
    list-style-type: none;
    padding-left: 1rem;
    margin-top: 0.5rem;
    display: none;
}

.submenu li {
    margin-bottom: 0.5rem;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}


.menu-item:hover,
.menu-item.selected {
    /* background-color: #e67300;  */
    color: #f57c00;
    /* font-weight: bold; */
}

.language-switcher {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.language-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: color 0.3s;
}

.language-button:hover {
    color: orange;
}

.language-button.ml-2 {
    margin-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/HeaderMobile.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B,cAAc;AACd,4BAA4B;AAC5B,MAAM;;AAEN;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA,uBAAuB;AACvB;IACI,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;;AAGA;;IAEI,gCAAgC;IAChC,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["/* Styles for mobile header */\n/* .navbar { */\n/* background-color: #333; */\n/* } */\n\n.menu-item {\n    padding: 1rem;\n    border-bottom: 1px solid #555;\n}\n\n.menu-reserve {\n    background-color: #f57c00;\n    color: #ffffff;\n}\n\n/* Styles for submenu */\n.submenu {\n    list-style-type: none;\n    padding-left: 1rem;\n    margin-top: 0.5rem;\n    display: none;\n}\n\n.submenu li {\n    margin-bottom: 0.5rem;\n}\n\n.visible {\n    display: block;\n}\n\n.hidden {\n    display: none;\n}\n\n\n.menu-item:hover,\n.menu-item.selected {\n    /* background-color: #e67300;  */\n    color: #f57c00;\n    /* font-weight: bold; */\n}\n\n.language-switcher {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 1rem;\n}\n\n.language-button {\n    background: none;\n    border: none;\n    color: white;\n    cursor: pointer;\n    font-size: 16px;\n    transition: color 0.3s;\n}\n\n.language-button:hover {\n    color: orange;\n}\n\n.language-button.ml-2 {\n    margin-left: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
