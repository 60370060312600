
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCircleUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { useSpring, animated } from 'react-spring';

const CallAndMessageButtons = () => {
    const messengerLink = 'https://www.messenger.com/t/1483293765229918/';
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const [hoveredButton, setHoveredButton] = useState(null);

    const shakeProps = useSpring({
        to: { transform: 'translateX(5px)' },
        from: { transform: 'translateX(0)' },
        reset: true,
        reverse: true,
        onRest: () => setHoveredButton(null),
    });

    const handleMouseEnter = (button) => {
        setHoveredButton(button);
    };

    const handleMouseLeave = () => {
        setHoveredButton(null);
    };

    return (
        <div className="fixed right-0 bottom-0 mb-8 mr-4 flex flex-col items-end z-50">
            {/* Nút Call */}
            <Link
                to="tel:1900068868"
                className={`text-white hover:text-orange-500 px-2 py-2 rounded mb-2 cursor-pointer`}
                onMouseEnter={() => handleMouseEnter('call')}
                onMouseLeave={handleMouseLeave}
            >
                <animated.div style={hoveredButton === 'call' ? shakeProps : {}}>
                    <FontAwesomeIcon icon={faPhone} className="mr-2 text-4xl" />
                </animated.div>
            </Link>

            {/* Nút Message */}
            <a
                href={messengerLink}
                className={`text-white hover:text-orange-500 px-2 py-2 rounded mb-2 cursor-pointer`}
                onMouseEnter={() => handleMouseEnter('message')}
                onMouseLeave={handleMouseLeave}
                target="_blank"
                rel="noopener noreferrer"
            >
                <animated.div style={hoveredButton === 'message' ? shakeProps : {}}>
                    <FontAwesomeIcon icon={faFacebookMessenger} className="mr-2 text-4xl" />
                </animated.div>
            </a>

            {/* Nút Quay lại đầu trang */}
            <button
                className={`text-white hover:text-orange-500 px-2 py-2 rounded cursor-pointer animate-bounce`}
                onMouseEnter={() => handleMouseEnter('scrollToTop')}
                onMouseLeave={handleMouseLeave}
                onClick={scrollToTop}
            >
                <animated.div style={hoveredButton === 'scrollToTop' ? shakeProps : {}}>
                    <FontAwesomeIcon icon={faCircleUp} className="text-4xl" />
                </animated.div>
            </button>
        </div>
    );
};

export default CallAndMessageButtons;