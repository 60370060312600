import axios from 'axios';
const api = axios.create({
    // baseURL: 'http://localhost:8080/api/',
    // baseURL: 'http://112.78.3.79:8081/api/'
    baseURL: 'https://be.saxnartclub.com/api/',
    // baseURL: 'http://192.168.68.135:8081/api/',
});

export const get = async (path) => {
    const response = await api.get(path);
    return response.data;
};

export const gets = async (path) => {
    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await api.get(path, config);
    return response.data;
};

export const post = async (path, values) => {
    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await api.post(path, values, config);
    return response;
};
export const pay = async (path, values) => {
    const response = await api.post(path, values);
    return response;
};

export const put = async (path, values) => {
    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await api.put(path, values, config);
    return response;
};

export const patch = async (path, values) => {
    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await api.patch(path, values, config);
    return response;
};

export const remove = async (path) => {
    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await api.delete(path, config);
    return response;
};
export const ApiRequest = async (values) => {
    try {
        const res = await api.get(
            `payment/vnpay-payment?vnp_TxnRef=${values.params.vnp_TxnRef}&vnp_ResponseCode=${values.params.vnp_ResponseCode}&vnp_OrderInfo=${values.params.vnp_OrderInfo}&vnp_BankCode=${values.params.vnp_BankCode}&vnp_Amount=${values.params.vnp_Amount}&vnp_CardType=${values.params.vnp_CardType}&vnp_PayDate=${values.params.vnp_PayDate}&vnp_TmnCode=${values.params.vnp_TmnCode}&vnp_TransactionNo=${values.params.vnp_TransactionNo}&vnp_TransactionStatus=${values.params.vnp_TransactionStatus}&vnp_SecureHash=${values.params.vnp_SecureHash}&vnp_BankTranNo=${values.params.vnp_BankTranNo}`,
        );
        return res.data;
    } catch (error) {
        return error.response;
    }
};

export default api;
