import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-parallax';
import { get } from '../../../services/axiosClient';
import { toast } from 'react-toastify';

const NormalBanner = () => {
    const [bannerImages, setBannerImages] = useState([]);
    const [isLoadingImages, setIsLoadingImages] = useState(true);

    useEffect(() => {
        fetchBannerImages();
    }, []);

    const fetchBannerImages = () => {
        get('imagehome')
            .then((data) => {
                if (Array.isArray(data.data) && data.data.length > 0) {
                    setBannerImages(data.data);
                }
                setIsLoadingImages(false);
            })
            .catch((error) => {
                // console.error('Error fetching banner images data:', error);
                toast.error('Lỗi tải dữ liệu hình ảnh!', error);
                setIsLoadingImages(false);
            });
    };

    const bgImage = bannerImages.length > 0 ? bannerImages[0].image : '';

    return (
        <Parallax
            bgImage={bgImage}
            strength={500}
            bgImageStyle={{
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="relative h-[800px] w-full">
                <div className="absolute top-0 left-0 right-0 bottom-0 z-[2] cursor-pointer" />
                <div className="relative z-10 text-white text-center w-full h-full flex justify-center items-center">
                    {/* Add any additional content here */}
                </div>
            </div>
        </Parallax>
    );
};

export default NormalBanner;
