import { createContext, useContext, useEffect, useState } from 'react';
import { get } from '../services/axiosClient';

const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export default function Context({ children }) {
    const [artist, setArtist] = useState([]);
    const [blog, setBlog] = useState([]);

    const fetchData = async () => {
        const result = await get('chuyen-nghe-si');
        const artistsWithTrueStatus = result.data.filter((artist) => artist.status === true);
        setArtist(artistsWithTrueStatus);

        const resultBlog = await get('blog');
        const blogWithTrueStatus = resultBlog.data.filter((blog) => blog.status === true);
        setBlog(blogWithTrueStatus);
    };

    // console.log(artist);
    // console.log(blog);

    useEffect(() => {
        fetchData();
    }, []);

    return <AppContext.Provider value={{ artist, setArtist, blog, setBlog }}>{children}</AppContext.Provider>;
}
