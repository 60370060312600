import React, { useState } from 'react';
import NormalBannerPC from './NormalBannerPC';
import NormalBannerMobile from './NormalBannerMobile';

const NormalBanner = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    

    return (
        <div>
            {isMobile ? (
                <NormalBannerMobile />
            ) : (
                <NormalBannerPC />
            )}
        </div>
    );
};

export default NormalBanner;
