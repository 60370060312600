import { lazy } from 'react';

const Home = lazy(() => import('../pages/Home'));
const ParentComponent = lazy(() => import("../pages/Sax N'Art/Artist/Artist/ParentComponent"));
const ParentComponentBlog = lazy(() => import("../pages/Sax N'Art/Artist/Blog/ParentComponent"));
const Event = lazy(() => import('../pages/Event/Event'));
const ManaShow = lazy(() => import('../admin/ManaShow'));
const Guide = lazy(() => import('../pages/Membership/Guide'));
const Category = lazy(() => import('../pages/Membership/Category'));
const Hotline = lazy(() => import('../pages/Membership/Hotline'));
const VnPayPaymentGuide = lazy(() => import('../pages/VnPayPaymentGuide'));
const ChinhSachBaoMat = lazy(() => import('../components/Footer/ChinhSachBaoMat'));
const ChinhSachThanhToan = lazy(() => import('../components/Footer/ChinhSachThanhToan'));
const ChinhSachHoanHuy = lazy(() => import('../components/Footer/ChinhSachHoanHuy'));
const DieuKhoanSuDung = lazy(() => import('../components/Footer/DieuKhoanSuDung'));
const Feedback = lazy(() => import('../components/contact/Feedback'));
const Contact = lazy(() => import('../components/contact/Contact'));
const BookingEvent = lazy(() => import('../components/contact/BookingEvent'));
const BookingDaily = lazy(() => import('../components/contact/BookingDaily'));
const Login = lazy(() => import('../pages/Login'));
const LoginSA = lazy(() => import('../admin_supper/Login'));
const Gallery = lazy(() => import('../pages/Gallery'));
const Story = lazy(() => import("../pages/Sax N'Art/Story/Story"));
const Artist = lazy(() => import("../pages/Sax N'Art/Artist/Artist/Artist"));
const Blog = lazy(() => import("../pages/Sax N'Art/Artist/Blog/Blog"));
const Booking = lazy(() => import('../pages/Booking/Booking'));
const Dashboard = lazy(() => import('../admin/Dashboard'));
const ManaBookingEvent = lazy(() => import('../admin/ManaBookingEvent'));
const ManaFeedback = lazy(() => import('../admin/ManaFeedback'));
const ManaContact = lazy(() => import('../admin/ManaContact'));
const ChangePassword = lazy(() => import('../admin/ChangePassword'));
const ManaBooking = lazy(() => import('../admin/ManaBooking'));
const ManaDaily = lazy(() => import('../admin/ManaDaily'));
const ManaArtists = lazy(() => import('../admin/ManaArtists'));
const ManaArtistDetail = lazy(() => import('../admin/ManaArtistDetail'));
const ManaBlogs = lazy(() => import('../admin/ManaBlogs'));
const ManaBlogDetail = lazy(() => import('../admin/ManaBlogDetail'));
const ManaShowDetail = lazy(() => import('../admin/ManaShowDetail'));
const ManaEvent = lazy(() => import('../admin/ManaEvent'));
const DefaultLayout = lazy(() => import('../components/layouts/DefaultLayout'));
const AdminLayout = lazy(() => import('../components/layouts/AdminLayout'));
const NormalLayout = lazy(() => import('../components/layouts/NormalLayout'));
const LoginLayout = lazy(() => import('../components/layouts/LoginLayout'));
const EventDetail = lazy(() => import('../pages/Event/EventDetail'));
const ManaGallery = lazy(() => import('../admin/ManaGallery'));
const ManaBanner = lazy(() => import('../admin/ManaBanner'));
const SettingMailSend = lazy(() => import('../admin/SettingMailSend'));
const changePasswordSA = lazy(() => import('../admin_supper/ChangePasswordSA'));
const loginChangePasswordSA = lazy(() => import('../admin_supper/LoginForgotPassword'));
const ManaUser = lazy(() => import('../admin_supper/ManaUser'));
const ManaUserDetail = lazy(() => import('../admin_supper/ManaUserDetail'));
const ManaNetwork = lazy(() => import('../admin_supper/ManaTrafficNetwork'));
const ManaStatistical = lazy(() => import('../admin_supper/ManaStatistical'));

const publicRoutes = [
    { path: '/', component: Home, name: 'Trang chủ', layout: DefaultLayout },
    { path: 'event', component: Event, layout: NormalLayout, name: 'Sự kiện', Authen: 'public' },
    {
        path: 'event/:eventId',
        component: EventDetail,
        layout: NormalLayout,
        name: 'Chi tiết sự kiện',
        Authen: 'public',
    },
    { path: 'guide', component: Guide, layout: NormalLayout, name: 'Hướng dẫn', Authen: 'public' },
    { path: 'category', component: Category, layout: NormalLayout, name: 'Phân loại & quyền lợi', Authen: 'public' },
    {
        path: 'hotline',
        component: Hotline,
        layout: NormalLayout,
        name: 'Số điện thoại hotline đặt chỗ ',
        Authen: 'public',
    },
    {
        path: 'huong-dan-thanh-toan-VNPAY',
        component: VnPayPaymentGuide,
        layout: NormalLayout,
        name: 'Hướng dẫn thanh toán VNPAY',
        Authen: 'public',
    },
    {
        path: 'chinh-sach-bao-mat',
        component: ChinhSachBaoMat,
        layout: NormalLayout,
        name: 'Chính sách bảo mật',
        Authen: 'public',
    },
    {
        path: 'chinh-sach-thanh-toan',
        component: ChinhSachThanhToan,
        layout: NormalLayout,
        name: 'Chính sách thanh toán',
        Authen: 'public',
    },
    {
        path: 'chinh-sach-hoan-huy',
        component: ChinhSachHoanHuy,
        layout: NormalLayout,
        name: 'Chính sách hoàn/hủy',
        Authen: 'public',
    },
    {
        path: 'dieu-khoan-su-dung',
        component: DieuKhoanSuDung,
        layout: NormalLayout,
        name: 'Điều khoản sử dụng',
        Authen: 'public',
    },

    { path: 'feedback', component: Feedback, layout: NormalLayout, name: 'Góp ý', Authen: 'public' },
    { path: 'contact', component: Contact, layout: NormalLayout, name: 'Liên hệ', Authen: 'public' },
    { path: 'bookingevent', component: BookingEvent, layout: NormalLayout, name: 'Đặt sự kiện', Authen: 'public' },
    { path: 'bookingdaily', component: BookingDaily, layout: NormalLayout, name: 'Đặt chỗ', Authen: 'public' },
    { path: 'login', component: Login, layout: LoginLayout, name: 'Đăng Nhập', Authen: 'public' },
    { path: 'loginsa', component: LoginSA, layout: LoginLayout, name: 'Đăng Nhập SA', Authen: 'public' },
    {
        path: 'forgot-password-sa',
        component: loginChangePasswordSA,
        layout: LoginLayout,
        name: 'Quên mật khẩu SA',
        Authen: 'public',
    },
    { path: 'gallery', component: Gallery, layout: NormalLayout, name: 'Gallery', Authen: 'public' },
    { path: "sax-n'art", component: Story, layout: NormalLayout, name: "Chuyện của Sax N'art", Authen: 'public' },
    { path: 'blog', component: Blog, layout: NormalLayout, name: 'Podcard & Blog', Authen: 'public' },
    {
        path: 'blog/:id/:title',
        component: ParentComponentBlog,
        layout: NormalLayout,
        name: 'Podcard & Blog',
        Authen: 'public',
    },
    { path: 'artist', component: Artist, layout: NormalLayout, name: 'Chuyện nghệ sĩ', Authen: 'public' },
    {
        path: 'artist/:id/:title',
        component: ParentComponent,
        layout: NormalLayout,
        name: 'Chuyện nghệ sĩ',
        Authen: 'public',
    },
    { path: 'booking', component: Booking, layout: NormalLayout, name: 'Đặt Chỗ', Authen: 'public' },
    { path: 'dashboard', component: Dashboard, layout: AdminLayout, name: 'Dashboard', Authen: 'private' },
    {
        path: 'dashboard/quan-ly-dat-su-kien',
        component: ManaBookingEvent,
        layout: AdminLayout,
        name: 'Quản lý đặt sự kiện',
        Authen: 'private',
    },
    {
        path: 'dashboard/quan-ly-feedback',
        component: ManaFeedback,
        layout: AdminLayout,
        name: 'Quản lý feedback',
        Authen: 'private',
    },
    {
        path: 'dashboard/quan-ly-lien-he',
        component: ManaContact,
        layout: AdminLayout,
        name: 'Quản lý liên hệ',
        Authen: 'private',
    },
    {
        path: 'dashboard/changePassword',
        component: ChangePassword,
        layout: AdminLayout,
        name: 'Đổi mật khẩu',
        Authen: 'private',
    },

    // {
    //     path: 'dashboard/quan-ly-dat-cho-su-kien',
    //     component: ManaBooking,
    //     layout: AdminLayout,
    //     name: 'Quản lý đặt chỗ sự kiện',
    //     Authen: 'private',
    // },
    {
        path: 'dashboard/quan-ly-dat-cho',
        component: ManaDaily,
        layout: AdminLayout,
        name: 'Quản lý đặt chỗ',
        Authen: 'private',
    },
    {
        path: 'dashboard/artist',
        component: ManaArtists,
        layout: AdminLayout,
        name: 'Quản lý chuyện nghệ sĩ',
        Authen: 'private',
    },
    {
        path: 'dashboard/quan-li-noi-dung-su-kien',
        component: ManaEvent,
        layout: AdminLayout,
        name: 'Quản lý nội dung sự kiện',
        Authen: 'private',
    },
    {
        path: 'dashboard/artist/update/:id',
        component: ManaArtistDetail,
        layout: AdminLayout,
        name: 'Chuyện nghệ sĩ',
        Authen: 'private',
    },
    {
        path: 'dashboard/artist/create',
        component: ManaArtistDetail,
        layout: AdminLayout,
        name: 'Chuyện nghệ sĩ',
        Authen: 'private',
    },
    {
        path: 'dashboard/show',
        component: ManaBlogs,
        layout: AdminLayout,
        name: 'Quản lý chuyện nghệ sĩ',
        Authen: 'private',
    },
    {
        path: 'dashboard/show/update/:id',
        component: ManaBlogDetail,
        layout: AdminLayout,
        name: 'Chuyện nghệ sĩ',
        Authen: 'private',
    },
    {
        path: 'dashboard/show/create',
        component: ManaBlogDetail,
        layout: AdminLayout,
        name: 'Chuyện nghệ sĩ',
        Authen: 'private',
    },
    {
        path: 'dashboard/booking',
        component: ManaShow,
        layout: AdminLayout,
        name: 'Quản lý show',
        Authen: 'private',
    },
    {
        path: 'dashboard/booking/create',
        component: ManaShowDetail,
        layout: AdminLayout,
        Authen: 'private',
    },
    {
        path: 'dashboard/booking/update/:id',
        component: ManaShowDetail,
        layout: AdminLayout,
        Authen: 'private',
    },
    {
        path: 'dashboard/quan-li-gallery',
        component: ManaGallery,
        layout: AdminLayout,
        name: 'Quản lý gallery',
        Authen: 'private',
    },
    {
        path: 'dashboard/quan-li-banner',
        component: ManaBanner,
        layout: AdminLayout,
        name: 'Quản lý banner',
        Authen: 'private',
    },
    {
        path: 'dashboard/mailServer',
        component: SettingMailSend,
        layout: AdminLayout,
        name: 'Cài đặt Mail Server',
        Authen: 'private',
    },
    {
        path: 'dashboard/changePasswordSA',
        component: changePasswordSA,
        layout: AdminLayout,
        name: 'Đổi mật khẩu Supper Admin',
        Authen: 'private',
    },
    {
        path: 'dashboard/manageUser',
        component: ManaUser,
        layout: AdminLayout,
        name: 'Quản lí User',
        Authen: 'private',
    },
    {
        path: 'dashboard/manageUser/update/:id',
        component: ManaUserDetail,
        layout: AdminLayout,
        name: 'Thông tin user',
        Authen: 'private',
    },
    {
        path: 'dashboard/manageNetwork',
        component: ManaNetwork,
        layout: AdminLayout,
        name: 'Quản lí Traffic Network',
        Authen: 'private',
    },
    {
        path: 'dashboard/manaStatistical',
        component: ManaStatistical,
        layout: AdminLayout,
        name: 'Thống kê',
        Authen: 'private',
    },
];

export { publicRoutes };
