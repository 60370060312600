import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { NamePageContext } from "../../App";

const NamePage = () => {
    const namePage = useContext(NamePageContext);

    return (
        <h3 className="mx-4 sm:mx-8 md:mx-16 lg:mx-40 xl:mx-40absolute left-20 p-4 flex">
            <Link to={'/'} className="flex text-white font-normal transition">
                <span className="mr-2 "><FontAwesomeIcon icon={faHouse} /></span>
                <p>Trang chủ</p>
            </Link>
            <p className="text-gray-400 ml-4">/ {namePage}</p>
        </h3>
    );
};

export default NamePage;
