import React, { useState, useEffect } from 'react';
import { get } from '../../../services/axiosClient';
import { toast } from 'react-toastify';

const NormalBanner = () => {
    const [bannerImages, setBannerImages] = useState([]);
    const [isLoadingImages, setIsLoadingImages] = useState(true);

    useEffect(() => {
        fetchBannerImages();
    }, []);

    const fetchBannerImages = () => {
        get('imagehome')
            .then((data) => {
                if (Array.isArray(data.data) && data.data.length > 0) {
                    setBannerImages(data.data);
                }
                setIsLoadingImages(false);
            })
            .catch((error) => {
                toast.error('Lỗi tải dữ liệu hình ảnh!', error);
                setIsLoadingImages(false);
            });
    };

    const bgImage = bannerImages.length > 0 ? bannerImages[0].image : '';

    return (
        <div 
            className="relative w-full h-64 bg-cover bg-center" 
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <div className="absolute top-0 left-0 w-full h-full"></div>
            <div className="relative z-10 text-white text-center flex items-center justify-center h-full">
                {/* Add any content you want to display on the banner here */}
            </div>
        </div>
    );
};

export default NormalBanner;
