const Loading = () => {
    return (
        <div>
        <style>{inlineStyles}</style>
        <div class="dots-container">
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
      </div>
      </div>
    )
}
const inlineStyles = ` 
body {
    background: #FFFFFF;
  }
  .dots-container{
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
  }
  .dots{
    animation: bounce 1.5s infinite linear;
    background: #FF6600;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    text-align: center;
    width: 20px;
  }
  
  .dots:nth-child(1){
    animation-delay: .2s;
  }
  .dots:nth-child(2){
    animation-delay: .4s;
  }
  .dots:nth-child(3){
    animation-delay: .6s;
  }
  .dots:nth-child(4){
    animation-delay: .8s;
  }
  .dots:nth-child(5){
    animation-delay: 1s;
  }
  @keyframes bounce {
      0% {
              transform: translateY(0);
      }
        15% {
          transform: translateY(-15px);
      }
      30% {
          transform: translateY(0);
      }
  }
  
`;

export default Loading