// Header.jsx
import React, { useState } from 'react';
import HeaderPC from './HeaderPC';
import HeaderMobile from './HeaderMobile';

const Header = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    
    // console.log('Is Mobile:', isMobile);

    return (
        <div>
            {isMobile ? (
                <HeaderMobile />
            ) : (
                <HeaderPC />
            )}
        </div>
    );
};

export default Header;
