import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { HomeIcon, InformationCircleIcon, NewspaperIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import logo from '../../img/logo-Saxnart.png';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get the current year

    const { t } = useTranslation(); // Initialize useTranslation
    const phoneNumber = '1900068868';

    const handlePhoneIconClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const navigate = useNavigate();

    const handleNewspaperIconClick = () => {
        navigate('/huong-dan-thanh-toan-VNPAY');
    };

    const handleChinhSachBaoMatClick = () => {
        navigate('/chinh-sach-bao-mat');
    };
    const handleChinhSachThanhToanClick = () => {
        navigate('/chinh-sach-thanh-toan');
    };
    const handleChinhSachHoanHuyClick = () => {
        navigate('/chinh-sach-hoan-huy');
    };
    const handleDieuKhoanSuDungClick = () => {
        navigate('/dieu-khoan-su-dung');
    };

    return (
        <div className="relative isolate overflow-hidden bg-gray-900">
            <div className="mx-auto max-w-7xl px-4 lg:px-4">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-4 gap-y-4 lg:max-w-none lg:grid-cols-3">
                    <div className="max-w-xl lg:max-w-lg mt-5">
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <img className="w-full max-h-20 mb-2 object-contain" src={logo} alt="Logo" />
                        </Link>
                        <div className="text-left ml-16 my-4 text-xs text-gray-400">
                            <p>
                                {t('footer.companyInfo.line1')}
                                <br />
                                {t('footer.companyInfo.line2')}
                                <br />
                                {t('footer.companyInfo.line3')}
                                <br />
                                {t('footer.companyInfo.line4')}
                                <br />
                                {t('footer.companyInfo.line5')}
                                <br />
                                {t('footer.companyInfo.line6')}
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-4 lg:pt-2 mt-5">
                        <div className="flex items-start" onClick={handlePhoneIconClick} role="button">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <PhoneIcon
                                    className="h-5 w-5 text-white hover:text-orange-600 cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.phone')}
                                </dt>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <HomeIcon className="h-5 w-5 text-white hover:text-orange-600" aria-hidden="true" />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.address')}
                                </dt>
                            </div>
                        </div>
                        <div className="flex items-start" onClick={handleNewspaperIconClick} role="button">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <NewspaperIcon
                                    className="h-5 w-5 text-white hover:text-orange-600 cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.policy')}
                                </dt>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <a
                                href="https://www.facebook.com/saxnart"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-orange-600 mr-3"
                            >
                                <i className="fab fa-facebook fa-lg"></i>
                            </a>
                            <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                {t('footer.facebook')}
                            </dt>
                        </div>
                        <div className="flex items-start" onClick={handleChinhSachThanhToanClick} role="button">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <InformationCircleIcon
                                    className="h-5 w-5 text-white hover:text-orange-600 cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.infor1')}
                                </dt>
                            </div>
                        </div>
                        <div className="flex items-start" onClick={handleDieuKhoanSuDungClick} role="button">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <InformationCircleIcon
                                    className="h-5 w-5 text-white hover:text-orange-600 cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.infor2')}
                                </dt>
                            </div>
                        </div>
                        <div className="flex items-start" onClick={handleChinhSachBaoMatClick} role="button">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <InformationCircleIcon
                                    className="h-5 w-5 text-white hover:text-orange-600 cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.infor3')}
                                </dt>
                            </div>
                        </div>
                        <div className="flex items-start" onClick={handleChinhSachHoanHuyClick} role="button">
                            <div className="rounded-md bg-white/5 p-1 ring-1 ring-white/10">
                                <InformationCircleIcon
                                    className="h-5 w-5 text-white hover:text-orange-600 cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-2">
                                <dt className="mt-2 font-semibold text-white hover:text-orange-600 text-xs">
                                    {t('footer.infor4')}
                                </dt>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:col-span-1">
                        <iframe
                            title="Embedded Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.9345693154432!2d106.7297275!3d10.8163193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752725630b16a3%3A0x44b57b321c71c31!2sSaxn&#39;Art%20Club!5e0!3m2!1sen!2s!4v1702907090304!5m2!1sen!2s"
                            width="360"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-1 mt-4">
                    <div className="lg:col-span-1 mx-auto">
                        <p className="text-center text-white text-xs">
                            {' '}
                            {t('footer.copyRight', { year: currentYear })}
                        </p>
                    </div>
                </div>
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 -bottom-24 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-[-10rem]">
                <svg
                    className="relative -z-10 -translate-x-1/2 rotate-[30deg] max-w-none -translate-y-32 -scale-x-[-1] text-gray-900 opacity-30"
                    viewBox="0 0 1155 678"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="url(#9c1c1b8c-3a88-40d1-a6d0-57cb0dbf8e52)"
                        fillOpacity=".3"
                        d="M317.821 379.409L474.236 324.634L504.838 368.15L671.69 315.905L796.861 400.653L1155 263.424V678H0V407.21L317.821 379.409Z"
                    />
                    <defs>
                        <linearGradient id="9c1c1b8c-3a88-40d1-a6d0-57cb0dbf8e52" x1="0" x2="1" y1="1" y2="0">
                            <stop offset="0%" stopColor="rgb(253 186 116)" />
                            <stop offset="100%" stopColor="rgb(237 100 255)" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default Footer;
