import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { post } from './axiosClient';

const IpAddressComponent = ({ onIpFetched }) => {
    const [ipAddress, setIpAddress] = useState(null);
    const [loading, setLoading] = useState(true);
    const [geolocationResult, setGeolocationResult] = useState(null);

    useEffect(() => {
        const getIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const data = response.data;
                setIpAddress(data.ip);
                setLoading(false);
                onIpFetched(data.ip, geolocationResult); // Gọi hàm callback khi lấy được địa chỉ IP và dữ liệu vị trí
            } catch (error) {
                // console.error('Error fetching IP address:', error);
                setLoading(false);
            }
        };

        getIpAddress();
    }, [onIpFetched, geolocationResult]);

    const APIkey = '90f8ed0e3ccf4944aed4c28920361524';
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function getLocationInfo(latitude, longitude) {
        const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.status.code === 200) {
                    // console.log('results:', data.results);
                    setGeolocationResult(data.results[0].formatted);
                } else {
                    // console.log('Reverse geolocation request failed.');
                }
            })
            .catch((error) => console.error(error));
    }

    function success(pos) {
        var crd = pos.coords;
        // console.log('Your current position is:');
        // console.log(`Latitude : ${crd.latitude}`);
        // console.log(`Longitude: ${crd.longitude}`);
        // console.log(`More or less ${crd.accuracy} meters.`);
        getLocationInfo(crd.latitude, crd.longitude);
        setGeolocationResult({ latitude: crd.latitude, longitude: crd.longitude, accuracy: crd.accuracy });
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                // console.log(result);
                if (result.state === 'granted') {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === 'prompt') {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === 'denied') {
                    // Show instructions to enable location
                }
            });
        } else {
            // console.log('Geolocation is not supported by this browser.');
        }
    }, []);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    {/* <h2>Your IP Address:</h2> */}
                    {/* <p>{ipAddress}</p> */}
                    {geolocationResult && (
                        <div>
                            {/* <h2>Geolocation:</h2>
                            <p>Latitude: {geolocationResult.latitude}</p>
                            <p>Longitude: {geolocationResult.longitude}</p>
                            <p>Accuracy: {geolocationResult.accuracy} meters</p> */}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const PostIpAddress = () => {
    const [postSuccess, setPostSuccess] = useState(false);

    const handleIpFetched = async (ipAddress, location) => {
        try {
            // Gửi yêu cầu POST tới API để tạo IP mới
            const response = await axios.post('https://be.saxnartclub.com/api/ip/create', {
                location: location, // Truyền location vào đây
                ip: ipAddress,
                link: window.location.href,
            });

            // console.log('API Response:', response.data);
            setPostSuccess(true);
        } catch (error) {
            // console.error('Error posting IP address:', error);
            setPostSuccess(false);
        }
    };

    return (
        <div>
            {/* <h2>Post IP Address to API</h2> */}
            <IpAddressComponent onIpFetched={handleIpFetched} />
            {/* {postSuccess && <p>IP Address successfully posted!</p>} */}
        </div>
    );
};

export default PostIpAddress;
